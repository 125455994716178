import React, {useState, useEffect,useRef,useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import Routes from './Routes';
import { getAndSetClicks } from './utils';
import CookiesAccept from './components/common/cookies';
import { SECURITY_MESSAGE } from './constants/global';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from './components/common/ChatBot/config';
import MessageParser from './components/common/ChatBot/MessageParser';
import ActionProvider from './components/common/ChatBot/ActionProvider';
import './components/common/ChatBot/_chatbot.scss';
import {ReactComponent as Logo} from './assets/Chaticon.svg'
import CommonHeader from "./components/common/landing/Header/common-header";
import CommonFooter from "./components/common/landing/Footer/common-footer";
import {RxCross1} from "react-icons/rx";
import CacheBuster from "./CacheBuster";


function handleContext(e) {
  e.preventDefault();
  return alert(SECURITY_MESSAGE);
}



function App() {
  const [show,setShow]=useState(false);

  const {loading, isLatest, refreshCacheAndReload} = CacheBuster()
  if(!loading && !isLatest) refreshCacheAndReload()

  let chatbotRef = useRef();
  window.sessionStorage.setItem("FlashMessagePopup",true);
  // useEffect(()=>{
  //   let handler =(e)=>{
  //     if(!chatbotRef.current.contains(e.target)){
  //       setShow(false);
  //       console.log("lol");
  //     }
  //   };
  //   document.addEventListener("mousedown",handler);
  //   return()=>{
  //     document.removeEventListener("mousedown",handler);
  //   }
  // })
  useEffect(()=>{
    window.location.replace("https://www.zigram.tech/prescreening-io/", "_self"); 
  },[])
  const openBot=(value)=>{
      setShow(value);
    
  }
  return (
<></>
  );
}


export default withRouter(App);
